<template>
  <div class="page">
      <!-- HEADING 1 IMAGE BIG -->

      <plcd-page-heading
        :title="page.title"
        :subtitle="page.plcd_page_subtitle"
        :svgbg="true"
      >
      </plcd-page-heading>

      <section class="page-content" id="contentSection">
        <bxs-layout>
          <bxs-row>
            <bxs-col
            cols="12"
            ds="6"
            class="bxs-col-no-padding"
            >
            <div class="page-content__text page-content__text--inverted" v-html="page && page.plcd_page_content">
            </div>
            </bxs-col>
            <bxs-col
            cols="12"
            ds="6"
            >
              <div
              ref="stickyBlock"
              class="stickyBlock"
              data-scroll
              data-scroll-sticky
              data-scroll-repeat="true"
              data-scroll-target="#contentSection"
              >
                <bxs-figure :src="page.img" />
                <h2 class="stickyBlock__title animation-ready medium">{{ page.title }}</h2>
              </div>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>
      <section class="tecnology-block">
        <bxs-layout>
          <bxs-row>
            <bxs-col
            cols="12"
            ds="4"
            >
            <div class="subpage" v-if="item1">
              <h3>{{ item1.title }}</h3>
              <div v-html="item1 && item1.plcd_page_content"></div>
              <bxs-btn
                append-icon="arrow-right"
                text
                class="btn-triangle"
                to="/tecnologia/uniformita-e-precisione-di-dose">
                  {{ $translate('Vai alla sezione', activeLang) }}
                </bxs-btn>
            </div>

            </bxs-col>
            <bxs-col
            cols="12"
            ds="4"
            >
              <div class="subpage" v-if="item2">
                <h3>{{ item2.title }}</h3>
                <div v-html="item2 && item2.plcd_page_content"></div>
                <bxs-btn
                  append-icon="arrow-right"
                  text
                  class="btn-triangle"
                  to="/tecnologia/strati-sottili">
                    {{ $translate('Vai alla sezione', activeLang) }}
                  </bxs-btn>
              </div>
            </bxs-col>
            <bxs-col
            cols="12"
            ds="4"
            >
              <div class="subpage" v-if="item3">
                <h3>{{ item3.title }}</h3>
                <div v-html="item3 && item3.plcd_page_content"></div>
                <bxs-btn
                  append-icon="arrow-right"
                  text
                  class="btn-triangle"
                  to="/tecnologia/bassa-intensita">
                    {{ $translate('Vai alla sezione', activeLang) }}
                  </bxs-btn>
              </div>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>
      <section class="faq">
        <bxs-layout>
          <bxs-row>
            <bxs-col
            cols="12"
            ds="12"
            >
              <h2>FAQ</h2>
              <bxs-collapse-item label="Ma il materiale diventa radioattivo dopo l’irraggiamento e va pertanto messo in quarantena?">
                <p>La risposta è no, la soglia di attivazione della materia è 10 MeV che è l’energia minima che i raggi dovrebbero avere per attivare la materia e renderla a sua volta radioattiva. I raggi gamma da decadimento del cobalto 60 emettono due raggi con energia nota e sempre costante rispettivamente di 1.17 e 1.33 MeV che per cui non saranno mai in grado di rendere radioattivi i materiali e non è necessaria alcuna quarantena.</p>
              </bxs-collapse-item>
              <bxs-collapse-item label="Effettuate anche analisi di laboratorio quali test di sterilità o bioburden?">
                <p>La risposta è no, siamo coloro che applicano il processo di irraggiamento per ottenere la sterilità di un prodotto, se effettuassimo anche le analisi di sterilità o le conte microbiche saremmo il controllore e controllato allo stesso tempo pertanto per scelta preferiamo lasciare la libertà ai nostri clienti di affidarsi a laboratori esterni totalmente indipendenti da noi per verificare il raggiungimento della sterilità tramite il processo di sterilizzazione.</p>
              </bxs-collapse-item>
              <bxs-collapse-item label="Mi garantite la sterilità?">
                <p>L’irraggiatore per normativa non può essere responsabile della sterilità in quanto questa dipende da diversi fattori: la carica batterica presente sul prodotto, il processo di irraggiamento e la tenuta del packaging. Per poter dichiarare un prodotto sterile infatti è in genere necessario fare delle validazioni per la parte di laboratorio (determinazione del bioburden e test di sterilità), per la determinazione della dose di irraggiamento, per il processo di irraggiamento stesso ed infine per la tenuta del confezionamento.</p>
              </bxs-collapse-item>
              <bxs-collapse-item label="Aprite le scatole per effettuare il trattamento?">
                <p>No, il trattamento avviene a scatola chiusa, i raggi gamma sono molto penetranti ed il vantaggio di questo tipo di sterilizzazione sta proprio nel fatto che si possa applicare ad imballo chiuso.</p>
              </bxs-collapse-item>
              <bxs-collapse-item label="Il prodotto si scalda durante il trattamento?">
                <p>No, il processo in sé non scalda il prodotto, tuttavia all’interno della cella di irraggiamento c’è qualche grado in più rispetto all’esterno.</p>
              </bxs-collapse-item>
              <bxs-collapse-item label="E’ necessario un packaging particolare?">
                <p>No, non ci sono requisiti particolari, va tenuto conto del fatto che alcune tipologie di plastica tendono ad ingiallire alle dosi più alte mentre il vetro imbrunisce già a dosi basse.</p>
              </bxs-collapse-item>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>
      <!--
        <bxs-col
        cols="12"
        ds="6"
        class="bxs-col-no-padding bxs-bg-col"
        >
      -->

      <plcd-driving-banner
        :title="page.plcd_driving_banner_page_title"
        :to="page.plcd_driving_banner_link"
        :src="page.plcd_driving_banner_image"
      >
      </plcd-driving-banner>

  </div>
</template>

<script>
import { gsap } from 'gsap'
import { mapState } from 'vuex'
export default {
    name: 'tecnologia',
    data () {
      return {
        is_preloaded: false,
        is_loading: true,
        item1: null,
        item2: null,
        item3: null
      }
    },
    computed: {
      ...mapState({
        language: state => state.language,
        pages(state) {
          const languagePages = {
              it: state.pages,
              en: state.pagesEn
          }
          return languagePages[this.language] || state.pages
          },
          page() {
            const objArray = Object.values(this.pages)
            return objArray.filter(pages => pages.template === 'template-tecnologia.php')[0]
          },
          activeLang() {
            return this.$store.state.language
          }
      })
    },
    mounted() {
      this.$nextTick(this.start)

      // Mutation Observer for Locomotive
      this.observer = new MutationObserver(mutations => {
        for (const m of mutations) {
          const newValue = m.target.getAttribute(m.attributeName)
          this.$nextTick(() => {
            this.onClassChange(newValue, m.oldValue)
          })
        }
      })

      this.observer.observe(this.$refs.stickyBlock, {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ['class']
      })
    },

    methods: {
      onClassChange(classAttrs) {
          const classList = classAttrs.split(' ')
          if (classList.includes('is-inview')) {
            this.animateBlock(this.$refs.stickyBlock)
          } else {
            this.animateBlockBack(this.$refs.stickyBlock)
          }
        },
        beforeDestroy() {
          this.observer.disconnect()
        },
        animateBlock(block) {
          const blockMotion = gsap.timeline({})

          blockMotion
              .to(block, {
                  margin: '50px',
                  duration: 0.5,
                  ease: 'power3.easeOut'
              })
        },
        animateBlockBack(block) {
          const blockMotion = gsap.timeline({})

          blockMotion
              .to(block, {
                  margin: '0',
                  duration: 0.5,
                  ease: 'power3.easeOut'
              })
        },
        async start() {
            await this.getPage1()
            this.is_preloaded = true
            await this.getPage2()
            this.is_preloaded = true
            await this.getPage3()
            this.is_preloaded = true
        },
        async getPage1() {
            this.is_loading = true

            try {
                this.item1 = await this.$store.dispatch('getPage', '/uniformita-e-precisione-di-dose')
            } catch (err) {
                alert(err)
            } finally {
                this.is_loading = false
            }
        },
        async getPage2() {
            this.is_loading = true

            try {
                this.item2 = await this.$store.dispatch('getPage', '/strati-sottili/')
            } catch (err) {
                alert(err)
            } finally {
                this.is_loading = false
            }
        },
        async getPage3() {
            this.is_loading = true

            try {
                this.item3 = await this.$store.dispatch('getPage', '/bassa-intensita')
            } catch (err) {
                alert(err)
            } finally {
                this.is_loading = false
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins.scss';
#contentSection{
  padding-bottom: 50px !important;
}

.stickyBlock{
  position: relative;
  padding: 70px 100px 0 100px;

  @include breakpoint(app_mobile) {
    display: none !important;
  }

  &__title{
    position: absolute;
    bottom: -120px;
    left: 50%;
    transform: translate(-50%, 0);
    opacity: 0;
    font-size: 52px;
    border-bottom: 5px solid var(--color-primary);
    white-space: nowrap;

    .is-inview &{
      opacity: 1;
    }
  }
}
.tecnology-block{
  padding: 100px 100px;
  background: #f2f2f2;

  .subpage{
    h3{
      font-size: 23px;
      @include SideBold;
      letter-spacing: 0.006em;
      margin-bottom: 28px;
    }
    div{
      color: #111;
      line-height: 24px;
      height: 126px;
      overflow: hidden;
      margin-bottom: 28px;
    }
  }
}

.faq{
  padding:100px 0;
  max-width: 1200px;
  margin: 0 auto;

  h2{
    margin-bottom: 30px;
  }

  p{
    padding: 10px 0 0 50px;
  }
  .collapse-item-comp{
    background: #eee;
  }
}
</style>
