<template>
    <div class="page">
        <!-- HEADING 1 IMAGE BIG -->

        <plcd-page-heading
          :title="page.title"
          :subtitle="page.plcd_page_subtitle"
          :svgbg="true"
        >
        </plcd-page-heading>

        <section class="page-content">
          <bxs-layout class="bxs-layout-fluid">
            <bxs-row>
              <bxs-col
              cols="12"
              ds="6"
              class="bxs-col-no-padding"
              style="padding: 60px 150px !important"
              >
                <bxs-figure
                src="/img/hero-about.jpg"
                >
              </bxs-figure>
              </bxs-col>
              <bxs-col
              cols="12"
              ds="6"
              class="bxs-col-no-padding"
              >
              <div class="page-content__text">
                <p>
                Motivazione al lavoro, tecnologie all’avanguardia, innovazione continua, qualità certificata, eticità, impegno sociale e rispetto per l’ambiente. Questi i <strong>valori essenziali</strong> sui quali fonda ogni decisione e proiezione futura dal 1969, anno di fondazione, la Gammatom.</p>
                <p>
                Anni di crescita nelle tecnologie d’irraggiamento di raggi gamma destinati a sterilizzare e sanificare prodotti per vari settori produttivi, tra cui la farmaceutica. Una comunicazione capace di acquisire nel tempo visibilità sul mercato nazionale ma soprattutto internazionale e competere attraverso una qualità di rilievo.</p>
                <p><strong>Formazione, ricerca e attività in Team sono gli elementi trainanti il successo di una PMI italiana collocata nell’eccellenza.</strong></p>
                <div class="about-signature">
                  <div class="animated-signature" ref="signature" data-scroll>
                    <bxs-figure src="/img/gerardo-soldini-signature.jpg" width="300" height="80"></bxs-figure>
                  </div>
                  <div class="about-signature__text">
                      CEO OF GAMMATOM
                  </div>
                </div>
              </div>
              </bxs-col>
            </bxs-row>
          </bxs-layout>
        </section>

        <section id="section_timeline">
            <bxs-layout max-width="1000" class="bxs-layout-vertical-padded">
                <h2>La nostra storia</h2>

                <div
                data-scroll
                data-scroll-sticky
                data-scroll-target="#section_timeline"
                class="timeline">
                    <div class="timeline--line"></div>
                    <div class="timeline--fade-top"></div>
                    <div class="timeline--fade-bottom"></div>

                    <ul
                    id="timeline-items"
                    class="timeline--items">
                        <li
                        v-for="n in 7"
                        :key="n"
                        :id="'timeline-item-' + n"
                        :class="['timeline--item', { 'timeline--item-actived': active === n }]">
                            <div
                            data-scroll
                            data-scroll-sticky
                            :data-scroll-target="'#timeline-item-' + n"
                            class="timeline--item--spacer"><div></div></div>

                            <!-- <div
                            data-scroll
                            data-scroll-sticky
                            :data-scroll-target="'#timeline-item-' + n"
                            class="timeline--item--line-progress"></div> -->

                            <bxs-row>
                                <bxs-col
                                cols="2"
                                ds="6"
                                class="py-0">
                                    <div
                                    data-scroll
                                    data-scroll-sticky
                                    :data-scroll-call="n"
                                    data-scroll-repeat="true"
                                    :data-scroll-target="'#timeline-item-' + n">
                                      <h2 class="timeline-years">{{ titles[n] }}</h2>
                                    </div>
                                </bxs-col>

                                <bxs-col
                                cols="10"
                                ds="6"
                                class="py-0">
                                    <div style="padding-left: 10%;">
                                        <div class="mb-6">
                                          <div class="timeline-text">
                                            {{ texts[n] }}
                                          </div>
                                        </div>

                                        <bxs-figure
                                        ratio="4/3"
                                        :src="imgs[n]" />
                                    </div>
                                </bxs-col>
                            </bxs-row>
                        </li>
                    </ul>
                </div>
            </bxs-layout>
        </section>

        <section class="page-content">
          <bxs-layout class="bxs-layout-fluid bxs-bg-grey">
            <bxs-row>
              <!--
                <div class="page-content__text about-caption">
                  <svg class="svg" version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="97.000000pt" height="116.000000pt" viewBox="0 0 97.000000 116.000000"
                    preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(0.000000,116.000000) scale(0.100000,-0.100000)"
                    fill="#ff5f00" stroke="none">
                    <path d="M689 1112 c-8 -11 -56 -70 -107 -131 l-94 -111 -100 125 -100 125
                    -135 0 c-105 0 -133 -3 -127 -12 5 -7 79 -102 166 -211 l158 -197 -61 -82
                    c-96 -129 -120 -187 -120 -291 -1 -176 139 -311 321 -311 59 0 85 5 124 23 64
                    31 118 88 153 159 23 48 27 70 27 142 0 102 -15 142 -103 263 -34 46 -61 88
                    -61 94 0 8 153 194 293 355 26 30 47 59 47 64 0 5 -60 11 -133 12 -123 3 -135
                    2 -148 -16z m-149 -664 c54 -77 61 -108 34 -159 -18 -36 -73 -62 -112 -53 -34
                    8 -79 49 -86 80 -4 13 0 39 8 58 13 31 93 146 102 146 2 0 26 -32 54 -72z"/>
                    </g>
                  </svg>
                  Un nostro tratto distintivo è quello di saperci specializzare in tutto quello che facciamo, manutenzione compresa. Se molte delle aziende di questo settore si affidano a specialisti esterni per operazioni particolari, quali per esempio la ricarica della sorgente o l’alienazione delle sorgenti esauste, Gammatom ha formato negli anni un team di personale specializzato in tali operazioni. Inoltre il personale di Gammatom, oltre ad aver partecipato attivamente alla progettazione degli impianti circa cinquant’anni fa, ha continuato e continua a studiare ed implementare miglioramenti all’impianto anno dopo anno.

                  <bxs-figure
                  src="/img/gammatom-notebook.png"
                  >
                </bxs-figure>
                </div>
              -->
                <div class="page-content__text about-notebook">
                  <bxs-col
                  cols="6"
                  ds="6"
                  class="bxs-col-no-padding"
                  >
                  <!--
                  <div class="svg-wrapper" data-scroll data-scroll-speed="2" data-scroll-delay="1.5">
                      <svg  class="svg" version="1.0" xmlns="http://www.w3.org/2000/svg"
                        width="97.000000pt" height="116.000000pt" viewBox="0 0 97.000000 116.000000"
                        preserveAspectRatio="xMidYMid meet">

                        <g transform="translate(0.000000,116.000000) scale(0.100000,-0.100000)"
                        fill="#ff5f00" stroke="none">
                        <path d="M689 1112 c-8 -11 -56 -70 -107 -131 l-94 -111 -100 125 -100 125
                        -135 0 c-105 0 -133 -3 -127 -12 5 -7 79 -102 166 -211 l158 -197 -61 -82
                        c-96 -129 -120 -187 -120 -291 -1 -176 139 -311 321 -311 59 0 85 5 124 23 64
                        31 118 88 153 159 23 48 27 70 27 142 0 102 -15 142 -103 263 -34 46 -61 88
                        -61 94 0 8 153 194 293 355 26 30 47 59 47 64 0 5 -60 11 -133 12 -123 3 -135
                        2 -148 -16z m-149 -664 c54 -77 61 -108 34 -159 -18 -36 -73 -62 -112 -53 -34
                        8 -79 49 -86 80 -4 13 0 39 8 58 13 31 93 146 102 146 2 0 26 -32 54 -72z"/>
                        </g>
                      </svg>
                    </div>
                    -->
                    <h1>Evidenze</h1>
                    <p>
                      Un nostro tratto distintivo è quello di saperci specializzare in tutto quello che facciamo, manutenzione compresa.<br>Se molte delle aziende di questo settore si affidano a specialisti esterni per operazioni particolari, quali per esempio la ricarica della sorgente o l’alienazione delle sorgenti esauste, Gammatom ha formato negli anni un team di personale specializzato in tali operazioni. Inoltre il personale di Gammatom, oltre ad aver partecipato attivamente alla progettazione degli impianti circa cinquant’anni fa, ha continuato e continua a studiare ed implementare miglioramenti all’impianto anno dopo anno.
                    </p>
                    <bxs-btn
                      append-icon="arrow-right"
                      class="btn-triangle"
                      href="https://online.fliphtml5.com/dcid/oqmj/#p=1"
                      text>
                        Company Profile
                    </bxs-btn>
                  </bxs-col>
                  <bxs-col
                  cols="6"
                  ds="6"
                  class="bxs-col-no-padding"
                  data-scroll data-scroll-speed="1.5" data-scroll-delay="1.5"
                  >
                  <img class="notebook-img" src="/img/gammatom-notebook.png" />
                </bxs-col>
              </div>
            </bxs-row>
          </bxs-layout>
        </section>

        <plcd-driving-banner
          :title="page.plcd_driving_banner_page_title"
          :to="page.plcd_driving_banner_link"
          :src="page.plcd_driving_banner_image"
        >
        </plcd-driving-banner>

    </div>
</template>

<script>
import { gsap } from 'gsap'
import { mapState } from 'vuex'
export default {
    name: 'about-us',
    computed: {
      ...mapState({
        language: state => state.language,
        pages(state) {
          const languagePages = {
              it: state.pages,
              en: state.pagesEn
          }
          return languagePages[this.language] || state.pages
          },
          page() {
            const objArray = Object.values(this.pages)
            return objArray.filter(pages => pages.template === 'template-about-us.php')[0]
          },
          activeLang() {
            return this.$store.state.language
          }
      })
    },
    data () {
        return {
            active: 1,
            scroll_progress: 0,
            imgs: [
                'https://images.pexels.com/photos/2280549/pexels-photo-2280549.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/2280549/pexels-photo-2280549.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/1366942/pexels-photo-1366942.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/3760067/pexels-photo-3760067.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/356040/pexels-photo-356040.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/954583/pexels-photo-954583.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/2280549/pexels-photo-2280549.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/209251/pexels-photo-209251.jpeg?auto=compress&cs=tinysrgb&w=1600'

            ],
            titles: [
              'Anni \'70',
              'Anni \'70',
              'Anni \'80',
              'Anni \'90',
              '2000 - 2010',
              '2014',
              '2017',
              '2019'
            ],
            texts: [
              'Prima in Italia ad applicare tecnologia per sterilizzazione, sterilizzazione delle sementi',
              'Prima in Italia ad applicare tecnologia per sterilizzazione, sterilizzazione delle sementi',
              'Anni \'80 Selezionata dall\'Esa e resa ``laboratorio altamente qualificato`` dal MIUR italiano',
              'Diviene officina farmaceutica. Ottiene la prima approvazione F.D.A.',
              'Prosegue il piano di ricerca per irraggiamento su nuovi materiali',
              '2014 Accreditation Certificate of Foreign Drugs Manufacturer - Tokyo, Japan',
              '2017 Autorizzazione al trattamento dei prodotti farmaceutici per il mercato USA e Canada',
              'Ampliamento magazzino, rifacimento struttura esterna sede'
            ]
        }
    },

    mounted () {
      // Timeline position calculator
      this.$nextTick(() => {
          this.$emitter.on('scroll-call', (v) => {
              this.active = parseInt(v)
          })
      })

      // Mutation Observer for Locomotive
      this.observer = new MutationObserver(mutations => {
        for (const m of mutations) {
          const newValue = m.target.getAttribute(m.attributeName)
          this.$nextTick(() => {
            this.onClassChange(newValue, m.oldValue)
          })
        }
      })

      this.observer.observe(this.$refs.signature, {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ['class']
      })
    },

    methods: {
      onClassChange(classAttrs) {
          const classList = classAttrs.split(' ')
          if (classList.includes('is-inview')) {
            this.animateSignature(this.$refs.signature)
            this.observer.disconnect()
          }
        },
        beforeDestroy() {
          this.observer.disconnect()
        },
        animateSignature(signature) {
          const wordMotion = gsap.timeline({})

          wordMotion.delay(0.8)

          wordMotion
              .fromTo(signature, {
                  clipPath: 'inset(0 100% 0 0)',
                  webkitClipPath: 'inset(0 100% 0 0)'
              }, {
                  clipPath: 'inset(0 0% 0 0)',
                  webkitClipPath: 'inset(0 0% 0 0)',
                  duration: 1.1,
                  ease: 'power3.easeOut'
              })
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

#section_timeline{
  margin-bottom: 80px;

  h2{
    text-align: center;
    font-size: 36px;

    @include breakpoint(app_mobile) {
      font-size: 28px;
    }
  }
}
.about-signature{
  display: flex;

  .animated-signature{
    clip-path: inset(0 100% 0 0);
    -webkit-clip-path: inset(0 100% 0 0);
  }

  @include breakpoint(mobile) {
    flex-direction: column;
  }

  &__text{
    margin-top: 30px;
    margin-left: 20px;
  }
}
.about-caption{
  @include MainItalic;
  border-top: 0;
  background: rgba(230, 230, 230, 0.65);
  font-size: 32px;
  padding-top: 182px;
  padding-bottom: 182px;
  padding-left: 315px;
  padding-right: 337px;
  margin-right: auto;
  margin-left: auto;
  line-height: 46px;
  text-align: justify;

  @include breakpoint(desktop) {
    padding-left: 215px;
    padding-right: 237px;
    padding-top: 102px;
    padding-bottom: 102px;
  }

  @include breakpoint(tablet_landscape) {
    padding-right: 80px;
    padding-left: 80px;
  }

  @include breakpoint(mobile) {
    padding: 50px;
    text-align: center;
    font-size: 22px;
    line-height: 30px;
  }
}
svg{
  width: 64px;
  height: 64px;
  position: absolute;
  left: 100px;
  top: 50%;

  @include breakpoint(tablet_landscape) {
    display: none !important;
  }
}

.about-notebook{
  @include SideRegular;
  border-top: 0;
  background: #efefef;
  font-size: 16px;
  margin-right: auto;
  margin-left: auto;
  line-height: 28px;
  text-align: justify;
  display: flex;
  padding: 0;
  max-width: 90%;
  margin: 0 auto;
  padding: 100px 0 100px 8vw;

  h1{
    margin-bottom: 10px;
  }

  .notebook-img{
    margin-top: -80px;
    width: 90%;
    transform: rotate(-5deg) skew(10deg, -10deg);
    margin: 0 70px 0;
  }
  .svg-wrapper{
    width: 64px;
    height: 64px;
    position: absolute;
    left: 100px;
    top: 50%;
  }

  p{
    opacity: 1;
  }
}
.bxs-bg-grey{
  background: #efefef;
}

.timeline .timeline-years{
  @include breakpoint(app_mobile) {
    writing-mode: vertical-rl;
  }
}

</style>
