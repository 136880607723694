import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import components from './components'
import directives from './directives'
import filters from './filters'
import { useToast } from 'vue-toastification'
import mitt from 'mitt'
import Http from './assets/libs/Http'
import { translate } from './assets/locale/locale'

const emitter = mitt()
const app = createApp(App)

app.config.globalProperties.$filters = filters
app.config.globalProperties.$emitter = emitter
app.config.globalProperties.$wp = new Http(process.env.VUE_APP_WP_API)
app.config.globalProperties.$translate = translate

components(app)
directives(app)

app.config.globalProperties.$toast = useToast()

app.config.errorHandler = (err, vm, info) => {
    console.log('error handler', err, vm, info)
    // const payload = Object.values(err.payload || {})
    let message = err.message || err.code || err

    if (err.code === 'resource.validation') {
        message += Object.values(err.fields)
    }

    vm.$toast.error(String(message))
}

if (process.env.NODE_ENV === 'production') console.log = function () {}

app.use(store).use(router).mount('#app')
