import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import Home from '../views/Home.vue'
import About from '../views/About.vue'
import PrincipiAttivi from '../views/PrincipiAttivi.vue'
import CampiDiApplicazione from '../views/CampiDiApplicazione.vue'
import NewsSingle from '../views/NewsSingle.vue'
import Error404 from '../views/Error404.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import CookiePolicy from '../views/CookiePolicy.vue'
import Certificazioni from '@/views/Certificazioni.vue'
import Contatti from '@/views/Contatti.vue'
import Media from '@/views/Media.vue'
import DifferenzaMetodiSterilizzazione from '@/views/DifferenzaMetodiSterilizzazione.vue'
import EffettiRaggiGamma from '@/views/EffettiRaggiGamma.vue'
import IlNostroTeam from '@/views/IlNostroTeam.vue'
import PressReleases from '@/views/PressReleases.vue'
import Storytelling from '@/views/Storytelling.vue'
import StratiSottili from '@/views/StratiSottili.vue'
import SuMisura from '@/views/SuMisura.vue'
import UniformitaPrecisioneDose from '@/views/UniformitaPrecisioneDose.vue'
import Tecnologia from '@/views/Tecnologia.vue'
import BassaIntensita from '@/views/BassaIntensita.vue'
import Polimeri from '@/views/Polimeri.vue'
import Press from '@/views/Press.vue'
import StaffSingle from '@/views/StaffSingle.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        alias: ['/en/']
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: About,
        alias: ['/en/about-us']
    },
    {
        path: '/campi-di-applicazione',
        name: 'campi-di-applicazione',
        component: CampiDiApplicazione,
        alias: ['/en/application-fields']
    },
    {
        path: '/certificazioni',
        name: 'certificazioni',
        component: Certificazioni,
        alias: ['/en/certifications']
    },
    {
        path: '/contatti',
        name: 'contatti',
        component: Contatti,
        alias: ['/en/contacts']
    },
    {
      path: '/cookie-policy',
      name: 'cookie-policy',
      component: CookiePolicy,
      alias: ['/en/cookie-policy']
    },
    {
        path: '/differenza-metodi-di-sterilizzazione',
        name: 'differenza-metodi-di-sterilizzazione',
        component: DifferenzaMetodiSterilizzazione,
        alias: ['/en/difference-sterilization-methods']
    },
    {
        path: '/effetti-dei-raggi-gamma',
        name: 'effetti-raggi-gamma',
        component: EffettiRaggiGamma,
        alias: ['/en/effects-of-gamma-rays']
    },
    {
      path: '/our-team-grid',
      name: 'il-nostro-team',
      component: IlNostroTeam,
      alias: ['/en/our-team-grid']
    },
    {
        path: '/media',
        name: 'media',
        component: Media,
        alias: ['/en/media']
    },
    {
        path: '/polimeri',
        name: 'polimeri',
        component: Polimeri,
        alias: ['/en/polymers']
    },
    {
        path: '/press',
        name: 'press',
        component: Press,
        alias: ['/en/press']
    },
    {
        path: '/press-releases',
        name: 'press-releases',
        component: PressReleases,
        alias: ['/en/press-releases']
    },
    {
        path: '/principi-attivi',
        name: 'principi-attivi',
        component: PrincipiAttivi,
        alias: ['/en/active-ingredients']
    },
    {
        path: '/privacy-1cy',
        name: 'privacy-policy',
        component: PrivacyPolicy,
        alias: ['/en/privacy-policy']
    },
    {
        path: '/tecnologia/strati-sottili',
        name: 'strati-sottili',
        component: StratiSottili,
        alias: ['/en/thin-layers']
    },
    {
        path: '/storytelling',
        name: 'storytelling',
        component: Storytelling,
        alias: ['/en/storytelling']
    },
    {
        path: '/su-misura',
        name: 'su-misura',
        component: SuMisura,
        alias: ['/en/custom-designed']
    },
    // CHECKED
    {
        path: '/tecnologia/uniformita-e-precisione-di-dose',
        name: 'uniformita-precisione-dose',
        component: UniformitaPrecisioneDose,
        alias: ['/en/dosage-uniformity-and-precision']
    },
    {
        path: '/tecnologia',
        name: 'tecnologia',
        component: Tecnologia,
        alias: ['/en/technology']
    },
    {
        path: '/tecnologia/bassa-intensita',
        name: 'bassa-intensita',
        component: BassaIntensita,
        alias: ['/en/low-intensity']
    },
    {
        path: '/staff/:slug',
        name: 'staff',
        component: StaffSingle
    },
    {
        path: '/news/:slug',
        name: 'news',
        component: NewsSingle
    },
    //
    {
        path: '/:pathMatch(.*)*',
        redirect: '/errors/404'
    },
    {
        path: '/errors/404',
        name: 'error_404',
        component: Error404
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    }
})

router.beforeEach((to, from, next) => {
    console.log('> router.beforeEach', !!store.state.access, to.path, to.name)

    // Checking language path
    if (to.path.startsWith('/en')) {
      store.commit('set_language', 'en')
    } else {
      store.commit('set_language', 'it')
    }

    store.commit('set_is_in_transition', true)
    store.commit('set_page_enter', to.name)
    store.commit('set_page_leave', from && from.name ? from.name : null)

    return next()
})

export default router
