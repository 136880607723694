<template>
  <section ref="fluidHero" class="plcd-fluid-hero" id="fluidHero">
    <div class="plcd-fluid-hero--title-group" ref="titleGroup">
      <div>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          width="97.000000pt" height="116.000000pt" viewBox="0 0 97.000000 116.000000"
          preserveAspectRatio="xMidYMid meet">

          <g transform="translate(0.000000,116.000000) scale(0.100000,-0.100000)"
          fill="#ff5f00" stroke="none">
          <path d="M689 1112 c-8 -11 -56 -70 -107 -131 l-94 -111 -100 125 -100 125
          -135 0 c-105 0 -133 -3 -127 -12 5 -7 79 -102 166 -211 l158 -197 -61 -82
          c-96 -129 -120 -187 -120 -291 -1 -176 139 -311 321 -311 59 0 85 5 124 23 64
          31 118 88 153 159 23 48 27 70 27 142 0 102 -15 142 -103 263 -34 46 -61 88
          -61 94 0 8 153 194 293 355 26 30 47 59 47 64 0 5 -60 11 -133 12 -123 3 -135
          2 -148 -16z m-149 -664 c54 -77 61 -108 34 -159 -18 -36 -73 -62 -112 -53 -34
          8 -79 49 -86 80 -4 13 0 39 8 58 13 31 93 146 102 146 2 0 26 -32 54 -72z"/>
          </g>
        </svg>
        <h1>gammatom</h1>
      </div>
      <h2>Tailor Made <strong>Gamma Irradiation</strong></h2>
    </div>
    <div class="plcd-fluid-hero--triangle" ref="triangle">
      <triangle-motion :arrow="true"></triangle-motion>
    </div>
    <h2 ref="heroSub" class="hero-subtitle">Italian<strong> Excellence</strong></h2>
  </section>
</template>

<script>
  import { Showcase } from '@/assets/libs/webgl/Showcase.js'
  // import image1 from '@/assets/img/gammatom-hero.jpeg'
  import image1 from '@/assets/img/gammatom-home-hero.png'
  import TriangleMotion from './TriangleMotion.vue'
  import { gsap } from 'gsap'

  export default {
  components: { TriangleMotion },
    name: 'plcd-fluid-hero',

    computed: {
      isPageTransitionEnded() {
        return this.$store.getters.get_is_transition
      },
      isPreloaderEnded() {
        return this.$store.getters.get_scroll_object
      }
    },

    watch: {
      isPageTransitionEnded(value) {
        if (value === false) {
          this.startAnimation()
        }
      }
    },

    mounted() {
      const container = document.getElementById('fluidHero')
      const slidesData = [
        {
          image: image1
        }
      ]

      const showcase = new Showcase(slidesData, {})

      showcase.mount(container)
      showcase.render()

      window.addEventListener('resize', function() {
        showcase.onResize()
      })

      window.addEventListener('mousemove', function(ev) {
        showcase.onMouseMove(ev)
      })

      // this.$store.commit('set_is_in_transition', true)
      // this.$store.commit('set_is_loading', false)
    },

    methods: {
      startAnimation() {
        const triangleMotion = gsap.timeline({})

        triangleMotion
          .fromTo(this.$refs.titleGroup, {
              opacity: '0'
          }, {
              opacity: '1',
              duration: 1.7,
              ease: 'linear'
          }).to(this.$refs.heroSub, {
            opacity: 1
          })
      }
    }
  }
</script>

<style lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

.plcd-fluid-hero {
  height: 102vh;
  position: relative;
  margin-top: 74px;

  @include breakpoint(tablet_portrait) {
    background: url('@/assets/img/gammatom-home-hero.png') no-repeat 40% center;
    background-size: cover;
  }

  canvas{
    @include breakpoint(tablet_portrait) {
      display: none !important;
    }
  }

  .hero-subtitle{
    position: absolute;
    left: 25.5%;
    bottom: 90px;
    color: white;
    text-transform: lowercase;
    opacity: 0;

    @include breakpoint(desktop) {
      left: 17%;
    }

    @include breakpoint(tablet_landscape) {
      left: 13%;
    }

    @include breakpoint(tablet_portrait) {
      bottom: 20px;
      left: 30px;
      font-size: 28px;
      letter-spacing: 0.01em;
    }

    strong{
      color: var(--color-primary);
    }
  }

  &--title-group {
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translate(0, -50%);
    margin-top: -50px;
    opacity: 0;

    @include breakpoint(tablet_portrait) {
      right: auto;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    h1 {
      @include MainHeavy;
      font-size: 60px;

      @include breakpoint(tablet_portrait) {
        font-size: 50px;
      }
    }

    h2 {
      text-transform: uppercase;
      font-size: 20px;
      letter-spacing: .1em;

      @include breakpoint(tablet_portrait) {
        font-size: 18px;
        white-space: nowrap;
      }
    }

    h1, h2 {
      color: var(--color-primary);

      strong {
        color: white;
      }
    }

    div {
      display: flex;

      h1 {
        margin-left: 15px;
      }

      svg {
        width: 60px;
        height: 80px;
        transform: translate(15px, -10px);

        @include breakpoint(tablet_portrait) {
          width: 40px;
          height: 50px;
        }
      }
    }
  }

  &--triangle {
    position: absolute;
    right: 18%;
    top: 50%;
    transform: translate(0, -50%);
    margin-top: 250px;

    @include breakpoint(tablet_landscape) {
      top: 20%;
      right: 10%;
      left: auto;
      margin-right: 150px;
      margin-top: 320px;
    }

    @include breakpoint(tablet_portrait) {
      top: 20%;
      right: 50%;
      left: auto;
      margin-right: -90px;
      margin-top: 320px;
    }

  }
}
</style>
