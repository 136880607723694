<template>
  <div class="page">
      <!-- HEADING 1 IMAGE BIG -->

      <plcd-page-heading
        :title="page.title"
        :subtitle="page.plcd_page_subtitle"
        :svgbg="true"
      >
      </plcd-page-heading>

      <section class="page-content" id="contentSection">
        <bxs-layout>
          <bxs-row>
            <bxs-col
            cols="12"
            ds="12"
            class="bxs-col-no-padding"
            >
            <div class="page-content__text">
              <div class="team-grid">
                <div class="team-grid__head">
                  <div class="team-grid__member">
                    <div class="team-grid__member__image">
                      <bxs-figure class="news--figure" :src="staffs[0].img_url" :alt="staffs[0].title" width="137" height="137">
                      </bxs-figure>
                    </div>
                    <div class="team-grid__member__role">
                      <span>{{ staffs[0].title }}</span><br>
                      <strong>{{ staffs[0].department }}</strong>
                    </div>
                    <div class="team-grid__member__btn">
                      <bxs-btn
                      append-icon="arrow-right"
                      text
                      class="btn-triangle"
                      :to="staffs[0].to">
                        {{ $translate('Guarda il profilo', activeLang) }}
                      </bxs-btn>
                    </div>
                  </div>
                </div>
                <div class="team-grid__row">
                  <div class="team-grid__member">
                    <div class="team-grid__member__image">
                      <bxs-figure class="news--figure" :src="staffs[1].img_url" :alt="staffs[1].title" width="137" height="137">
                      </bxs-figure>
                    </div>
                    <div class="team-grid__member__role">
                      <span>{{ staffs[1].title }}</span><br>
                      <strong>{{ staffs[1].department }}</strong>
                    </div>
                    <div class="team-grid__member__btn">
                      <bxs-btn
                      append-icon="arrow-right"
                      text
                      class="btn-triangle"
                      :to="staffs[1].to">
                        {{ $translate('Guarda il profilo', activeLang) }}
                      </bxs-btn>
                    </div>
                  </div>
                  <div class="team-grid__member">
                    <div class="team-grid__member__image">
                      <bxs-figure class="news--figure" :src="staffs[2].img_url" :alt="staffs[1].title" width="137" height="137">
                      </bxs-figure>
                    </div>
                    <div class="team-grid__member__role">
                      <span>{{ staffs[2].title }}</span><br>
                      <strong>{{ staffs[2].department }}</strong>
                    </div>
                    <div class="team-grid__member__btn">
                      <bxs-btn
                      append-icon="arrow-right"
                      text
                      class="btn-triangle"
                      :to="staffs[2].to">
                        {{ $translate('Guarda il profilo', activeLang) }}
                      </bxs-btn>
                    </div>
                  </div>
                </div>
                <div class="team-grid__row">
                  <div class="team-grid__member" v-for="(staffs, i) in staffs.slice(3)" :key="i">
                    <div class="team-grid__member__image">
                      <bxs-figure class="news--figure" :src="staffs.img_url" :alt="staffs.title" width="137" height="137">
                      </bxs-figure>
                    </div>
                    <div class="team-grid__member__role">
                      <span>{{ staffs.title }}</span><br>
                      <strong>{{ staffs.department }}</strong>
                    </div>
                    <div class="team-grid__member__btn">
                      <bxs-btn
                      append-icon="arrow-right"
                      text
                      class="btn-triangle"
                      :to="staffs.to">
                        {{ $translate('Guarda il profilo', activeLang) }}
                      </bxs-btn>
                    </div>
                  </div>
                  <!--
                  <div class="team-grid__member" v-for="(staffs, i) in staffs.slice(3)" :key="i">
                    <div class="team-grid__member__image">
                      <bxs-figure class="news--figure" :src="staffs.img_url" :alt="staffs.title" width="137" height="137">
                      </bxs-figure>
                    </div>
                    <div class="team-grid__member__role">
                      <span>{{ staffs.title }}</span><br>
                      <strong>{{ staffs.department }}</strong>
                    </div>
                    <div class="team-grid__member__btn">
                      <bxs-btn
                      append-icon="arrow-right"
                      text
                      class="btn-triangle"
                      :to="staffs.to">
                        {{ $translate('Guarda il profilo', activeLang) }}
                      </bxs-btn>
                    </div>
                  </div>
                  -->
                </div>
              </div>
            </div>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>
      <!--
        <bxs-col
        cols="12"
        ds="6"
        class="bxs-col-no-padding bxs-bg-col"
        >
      -->

      <plcd-driving-banner
        :title="page.plcd_driving_banner_page_title"
        :to="page.plcd_driving_banner_link"
        :src="page.plcd_driving_banner_image"
      >
      </plcd-driving-banner>

  </div>
</template>

<script>
import { gsap } from 'gsap'
import { mapState } from 'vuex'
export default {
    name: 'il-nostro-team',
    computed: {
      ...mapState({
        language: state => state.language,
        pages(state) {
          const languagePages = {
              it: state.pages,
              en: state.pagesEn
          }
          return languagePages[this.language] || state.pages
          },
          page() {
            const objArray = Object.values(this.pages)
            return objArray.filter(pages => pages.template === 'template-il-nostro-team.php')[0]
          },
          staffs: state => state.staffs.docs,
          activeLang() {
            return this.$store.state.language
          }
      })
    },

    mounted() {
    },

    methods: {
      onClassChange(classAttrs) {
          const classList = classAttrs.split(' ')
          if (classList.includes('is-inview')) {
            this.animateBlock(this.$refs.stickyBlock)
          } else {
            this.animateBlockBack(this.$refs.stickyBlock)
          }
        },
        beforeDestroy() {
          this.observer.disconnect()
        },
        animateBlock(block) {
          const blockMotion = gsap.timeline({})

          blockMotion
              .to(block, {
                  margin: '50px',
                  duration: 0.5,
                  ease: 'power3.easeOut'
              })
        },
        animateBlockBack(block) {
          const blockMotion = gsap.timeline({})

          blockMotion
              .to(block, {
                  margin: '0',
                  duration: 0.5,
                  ease: 'power3.easeOut'
              })
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/mixins.scss';

.team-grid{
  max-width: 1200px;
  margin: 0 auto;

  .bxs-btn .bxs-btn-innertext{
    font-size: 17px !important;
  }

  &__head{
    display: flex;
    margin-bottom: 50px;

    > .team-grid__member{
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__row{
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }

  &__member{
    text-align: center;
    margin: 0 30px;

    .figure{
      margin-left: auto;
      margin-right: auto;
    }
    &__role{
      color: var(--color-primary);
      font-size: 20px;
      font-weight: 600;
      margin: 15px 0 10px;
      letter-spacing: .02em;

      strong{
        color: #222;
        font-size: 16px;
        font-weight: normal;
      letter-spacing: 0;
      }
    }
  }
}

</style>
