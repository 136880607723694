<template>

  <section class="driving-banner">
    <bxs-layout class="bxs-layout-fluid">
      <bxs-row>
        <bxs-col
        cols="12"
        ds="6"
        class="bxs-col-no-padding"
        style="padding: 100px 250px 100px 250px !important"
        >
          <bxs-figure
          :src="src"
          data-scroll
          :data-scroll-offset="percentage"
          >
        </bxs-figure>
        </bxs-col>
        <bxs-col
        cols="12"
        ds="6"
        class="bxs-col-no-padding"
        >
          <bxs-layout class="bxs-layout-vertical-padded">
            <div class="wrapper">
              <h2>{{ title }}</h2>
              <plcd-triangle-motion :arrow="false" :fullTriangle="true" data-scroll></plcd-triangle-motion>
              <div class="button-set">
                <bxs-btn
                  append-icon="arrow-right"
                  text
                  class="btn-triangle"
                  :to="to"
                >{{ $translate('Approfondisci', activeLang) }}
                </bxs-btn>
              </div>
            </div>
          </bxs-layout>
        </bxs-col>
      </bxs-row>
    </bxs-layout>
  </section>

</template>

<script>
export default {
    name: 'plcd-driving-banner',
    props: {
        title: {
            type: String,
            required: false,
            default: 'Titolo della pagina'
        },
        to: {
            type: String,
            required: false,
            default: ''
        },
        src: {
            type: String,
            required: false,
            default: ''
        },
        btnLabel: {
            type: String,
            required: false,
            default: 'Approfondisci'
        },
        percentage: {
          type: String,
          required: false,
          default: '20%'
        }
    },
    computed: {
      activeLang() {
        return this.$store.state.language
      }
    }
}

</script>
<style scoped lang="scss">
.driving-banner{
  border-top: 2px solid var(--color-primary);
  padding: 0;
  background: url('@/assets/svg/gammatom-endless-bg.svg');

  .wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -80px;
  }

  h2{
    font-size: 40px;
    text-align: center;
  }

  .button-set{
    margin-top: 169px;
  }
}

</style>
