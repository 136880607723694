<template>
    <div
    :class="classes"
    :style="measurable_styles">
        <slot />
    </div>
</template>

<script>
import { measurable } from '@/mixins'

export default {
    name: 'bxs-layout',
    mixins: [measurable],
    props: {
        fluid: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        classes () {
            return ['bxs-layout', {
                'bxs-layout-fluid': this.fluid
            }]
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

.bxs-layout {
    position: relative;
    width: 90%;
    max-width: var(--boxed-ui);

    margin-left: auto;
    margin-right: auto;

    // padding-left: var(--size-layout);
    // padding-right: var(--size-layout);

    &.bxs-layout-fluid {
        max-width: 100%;
        width: 100%;
    }

    &.bxs-layout-vertical-padded{
      margin-top: var(--distance-vertical-d);
      margin-bottom: var(--distance-vertical-d);
    }
}
</style>
