<template>
  <div
  ref="triangleMotion"
  class="plcd-triangle-motion"
  :class="{'plcd-triangle-motion--full-triangle': fullTriangle}">
    <div ref="triangleMotion1" class="plcd-triangle-motion--el1"></div>
    <div ref="triangleMotion2" class="plcd-triangle-motion--el2"></div>
    <div ref="triangleMotion3" class="plcd-triangle-motion--el3"></div>

    <a
    v-if="arrow"
    href="#"
    ref="triangleArrow"
    class="plcd-triangle-motion--arrow"
    @click="scrollNext($event)">
      <div class="plcd-triangle-motion--arrow--el"></div>
    </a>

    <div
    v-if="fullTriangle"
    ref="fullTriangle"
    class="plcd-triangle-motion__fullTriangle"></div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
export default {
  name: 'plcd-triangle-motion',
  props: {
    arrow: {
      type: Boolean,
      required: false,
      default: false
    },

    fullTriangle: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  mounted() {
    this.startTriangelAnimation()

    if (this.fullTriangle) {
      // Mutation Observer for Locomotive
      this.observer = new MutationObserver(mutations => {
        for (const m of mutations) {
          const newValue = m.target.getAttribute(m.attributeName)
          this.$nextTick(() => {
            this.onClassChange(newValue, m.oldValue)
          })
        }
      })

      this.observer.observe(this.$refs.triangleMotion, {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ['class']
      })
    }
  },

  methods: {
    startTriangelAnimation() {
      const triangleMotion = gsap.timeline({})
      triangleMotion
          .fromTo(this.$refs.triangleMotion1, {
              clipPath: 'inset(0 100% 0 0)',
              webkitClipPath: 'inset(0 100% 0 0)'
          }, {
              clipPath: 'inset(0 0% 0 0)',
              webkitClipPath: 'inset(0 0% 0 0)',
              duration: 1,
              ease: 'linear'
          })
          .fromTo(this.$refs.triangleMotion3, {
              clipPath: 'inset(0 0 100% 0)',
              webkitClipPath: 'inset(0 0 100% 0)'
          }, {
              clipPath: 'inset(0 0 0% 0)',
              webkitClipPath: 'inset(0 0 0% 0)',
              duration: 0.4,
              ease: 'linear'
          })
          .fromTo(this.$refs.triangleMotion2, {
              clipPath: 'inset(0 0 100% 0)',
              webkitClipPath: 'inset(0 0 100% 0)'
          }, {
              clipPath: 'inset(0 0 0% 0)',
              webkitClipPath: 'inset(0 0 0% 0)',
              duration: 0.3,
              ease: 'linear'
          })
          .fromTo(this.$refs.triangleArrow, {
            opacity: 0
          }, {
              opacity: 0.8,
              duration: 0.3,
              ease: 'linear'
          })
      },

    fullTriangelAnimation() {
      const fullTriangleMotion = gsap.timeline({})

      fullTriangleMotion.delay(0.9)

      fullTriangleMotion
          .fromTo(this.$refs.triangleMotion1, {
              clipPath: 'inset(0 100% 0 0)',
              webkitClipPath: 'inset(0 100% 0 0)'
          }, {
              clipPath: 'inset(0 0% 0 0)',
              webkitClipPath: 'inset(0 0% 0 0)',
              duration: 0.5,
              ease: 'linear'
          })
          .fromTo(this.$refs.triangleMotion3, {
              clipPath: 'inset(0 0 100% 0)',
              webkitClipPath: 'inset(0 0 100% 0)'
          }, {
              clipPath: 'inset(0 0 0% 0)',
              webkitClipPath: 'inset(0 0 0% 0)',
              duration: 0.2,
              ease: 'linear'
          })
          .fromTo(this.$refs.triangleMotion2, {
              clipPath: 'inset(0 0 100% 0)',
              webkitClipPath: 'inset(0 0 100% 0)'
          }, {
              clipPath: 'inset(0 0 0% 0)',
              webkitClipPath: 'inset(0 0 0% 0)',
              duration: 0.2,
              ease: 'linear'
          })
            .fromTo(this.$refs.fullTriangle, {
              borderTopWidth: 0,
              borderRightWidth: 0,
              borderLeftWidth: 0
          }, {
              borderTopWidth: 74,
              borderRightWidth: 74,
              borderLeftWidth: 74,
              duration: 0.3,
              ease: 'power4'
          })
      },

      // Data Scroll trigger
      onClassChange(classAttrs) {
        const classList = classAttrs.split(' ')

        if (classList.includes('is-inview')) {
          this.animateImage()
          this.observer.disconnect()
        }
      },
      beforeDestroy() {
        this.observer.disconnect()
      },
      animateImage() {
        this.fullTriangelAnimation()
      },
      scrollNext(event) {
          event.preventDefault()

          this.$root.scroll.scrollTo('#tellingPayoff')
          // const globalScroll = this.$store.getters.get_scroll_object
          // const target = document.querySelector('#tellingPayoff')
          // globalScroll.scroll.scrollTo(target)
      }

  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';
  .plcd-triangle-motion{
    position: relative;
    width: 200px;
    // opacity: 0;

    &--full-triangle{
      margin: 62px auto 0;
      width: 147px;
    }

    &__fullTriangle{
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 0 0;
      border-color: var(--color-primary) transparent transparent transparent;
      transform: translate(0, -4px);
      position: absolute;
    }

    &--el1{
      position: absolute;
      top:0;
      left:0;
      width: 300px;
      height: 1px;
      background: #fff;
      transform-origin: center;

      @include breakpoint(tablet_portrait) {
        width: 190px;
      }

      .plcd-triangle-motion--full-triangle &{
        background: var(--color-primary);
        width: 147px;
        top: -5px;
      }
    }

    &--el2{
      position: absolute;
      width: 1px;
      height: 210px;
      background: #fff;
      transform: rotate(-225deg);
      left: 75px;
      top: -30px;
      transform-origin: center;

      @include breakpoint(tablet_portrait) {
        height: 135px;
        top: -20px;
        left: 47px;
      }

      .plcd-triangle-motion--full-triangle &{
        background: var(--color-primary);
        height: 105px;
        left: 36px;
        top: -20px;
      }
    }

    &--el3{
      position: absolute;
      width: 1px;
      height: 210px;
      background: #fff;
      transform: rotate(45deg);
      right: -25px;
      top: -30px;
      transform-origin: center;

      @include breakpoint(tablet_portrait) {
        height: 135px;
        right: 58px;
        top: -18px;
      }

      .plcd-triangle-motion--full-triangle &{
        background: var(--color-primary);
        height: 105px;
        right: 36px;
        top: -20px;
      }
    }

    &--arrow{
      position: absolute;
      top: 40px;
      left: 130px;
      width: 40px;
      height: 20px;
      cursor: pointer;
      transform: rotate(90deg);
      opacity: 0;
      padding: 25px 0px;
      animation-duration: 2s;
      animation-name: breathe;
      animation-iteration-count: infinite;

      @include breakpoint(tablet_portrait) {
        left: 75px;
        top: 20px;
      }

      &:hover {
        animation-play-state: paused;
      }

      &--el{
        position: absolute;
        top: 25px;
        width: 90%;
        height: 2px;
        background-color: #fff;
        // background-color: var(--color-primary);

        @include breakpoint(tablet_portrait) {
          width: 58%;
          height: 1px;
        }

        &::after, &::before{
          content: '';
          position: absolute;
          width: 60%;
          height: 2px;
          right: -3px;
          background-color: #fff;

          @include breakpoint(tablet_portrait) {
            height: 1px;
          }
        }

        &::after{
          top: -8px;
          transform: rotate(45deg);

          @include breakpoint(tablet_portrait) {
            top: -5px;
          }
        }

        &::before{
          top: 8px;
          transform: rotate(-45deg);

          @include breakpoint(tablet_portrait) {
            top: 5px;
          }
      }
    }
  }
}
@keyframes breathe{

  0% {
    transform: rotate(90deg) scale(1);
  }

  50% {
    transform: rotate(90deg) scale(1.4);
  }
}

</style>
