<template>
    <!-- v-sticky -->
    <header
      :class="[
        'plcd-navbar',
        isHome ? 'homeNav' : ''
    ]">
        <bxs-layout>
            <!-- desktop nav -->
            <bxs-row
            no-gutters
            align="center"
            class="desktop-navbar"
            >
              <logo />
              <div class="link-wrapper">
                <a href="#" @click.prevent>
                  <div class="trigger">
                    <span>Why Us?</span>
                    <span class="revert">Why Us?</span>
                  </div>
                </a>
                <div class="subnav">
                  <router-link to="/about-us">
                    <div class="trigger">
                      <span>About Us</span>
                      <span class="revert">About Us</span>
                    </div>
                  </router-link>
                  <router-link to="/our-team-grid">
                    <div class="trigger">
                      <span>Team</span>
                      <span class="revert">Team</span>
                    </div>
                  </router-link>
                  <router-link to="/su-misura">
                    <div class="trigger">
                      <span>Su Misura</span>
                      <span class="revert">Su Misura</span>
                    </div>
                  </router-link>
                  <div class="nav-bg"></div>
                </div>
              </div>
              <div class="link-wrapper">
                <a href="#" @click.prevent>
                  <div class="trigger">
                    <span>Tecnologia</span>
                    <span class="revert">Tecnologia</span>
                  </div>
                </a>
                <div class="subnav">
                  <router-link to="/tecnologia">
                    <div class="trigger">
                      <span>Tecnologia</span>
                      <span class="revert">Tecnologia</span>
                    </div>
                  </router-link>
                  <router-link to="/differenza-metodi-di-sterilizzazione">
                    <div class="trigger">
                      <span>Differenza Metodi di Sterilizzazione</span>
                      <span class="revert">Differenza Metodi di Sterilizzazione</span>
                    </div>
                  </router-link>
                  <div class="nav-bg"></div>
                </div>
              </div>
              <div class="link-wrapper">
                <router-link to="/principi-attivi">
                  <div class="trigger">
                    <span>Principi Attivi</span>
                    <span class="revert">Principi Attivi</span>
                  </div>
                </router-link>
              </div>
              <div class="link-wrapper">
                <a href="#" @click.prevent>
                  <div class="trigger">
                    <span>Applicazioni</span>
                    <span class="revert">Applicazioni</span>
                  </div>
                </a>
                <div class="subnav">
                  <router-link to="/campi-di-applicazione">
                    <div class="trigger">
                      <span>Campi di Applicazione</span>
                      <span class="revert">Campi di Applicazione</span>
                    </div>
                  </router-link>
                  <router-link to="/polimeri">
                    <div class="trigger">
                      <span>Polimeri</span>
                      <span class="revert">Polimeri</span>
                    </div>
                  </router-link>
                  <router-link to="/effetti-dei-raggi-gamma">
                    <div class="trigger">
                      <span>Effetti dei raggi gamma</span>
                      <span class="revert">Effetti dei raggi gamma</span>
                    </div>
                  </router-link>
                  <div class="nav-bg"></div>
                </div>
              </div>
              <div class="link-wrapper">
                <a href="#" @click.prevent>
                  <div class="trigger">
                    <span>News</span>
                    <span class="revert">News</span>
                  </div>
                </a>
                <div class="subnav">
                  <router-link to="/press-releases">
                    <div class="trigger">
                      <span>News</span>
                      <span class="revert">News</span>
                    </div>
                  </router-link>
                  <router-link to="/press">
                    <div class="trigger">
                      <span>Press</span>
                      <span class="revert">Press</span>
                    </div>
                  </router-link>
                  <router-link to="/media">
                    <div class="trigger">
                      <span>Media</span>
                      <span class="revert">Media</span>
                    </div>
                  </router-link>
                  <router-link to="/storytelling">
                    <div class="trigger">
                      <span>Storytelling</span>
                      <span class="revert">Storytelling</span>
                    </div>
                  </router-link>
                  <div class="nav-bg"></div>
                </div>
              </div>
              <div class="link-wrapper">
                  <router-link to="/certificazioni">
                  <div class="trigger">
                    <span>Certificazioni</span>
                    <span class="revert">Certificazioni</span>
                  </div>
                </router-link>
              </div>
              <div class="link-wrapper">
                <a class="contact-link" href="#" @click.prevent>
                  <div class="trigger">
                    <span>Contatti</span>
                    <span class="revert">Contatti</span>
                  </div>
                </a>
              </div>
              <div class="link-wrapper lang-selector">
                <a href="#" @click.prevent>
                  <div class="trigger">
                    <span>IT</span>
                    <span class="revert">EN</span>
                  </div>
                </a>
              </div>
              <!--
              <div class="lang-selector">
                <div class="lang-label">Italiano</div>
                <a href="#" class="it" @click.prevent></a>
                <a href="#" class="en" @click.prevent></a>
              </div>
               -->
            </bxs-row>
            <bxs-row
            no-gutters
            align="center"
            class="mobile-navbar"
            >
                <bxs-col cols="6">
                    <logo />
                </bxs-col>

                <bxs-col
                cols="6"
                class="mx-auto text-right">
                    <a
                      ref="menuBtn"
                      href="#"
                      class="plate plate4"
                      @click="menuAction($event, $target)"
                    >
                      <!--<div class="plate-label animation-ready fast">Menù</div>-->
                      <svg class="burger" version="1.1" height="100" width="100" viewBox="0 0 100 100">
                        <path class="line line1" d="M 50,35 H 30" />
                        <path class="line line2" d="M 50,35 H 70" />
                        <path class="line line3" d="M 50,50 H 30" />
                        <path class="line line4" d="M 50,50 H 70" />
                        <path class="line line5" d="M 50,65 H 30" />
                        <path class="line line6" d="M 50,65 H 70" />
                      </svg>
                      <svg class="x" version="1.1" height="100" width="100" viewBox="0 0 100 100">
                        <path class="line" d="M 34,32 L 66,68" />
                        <path class="line" d="M 66,32 L 34,68" />
                      </svg>
                    </a>
                <!-- <button
                type="button"
                style="font-size: 21px;"
                @click.prevent="$emit('click:menu')">{{ on ? 'Close' : 'Menu' }}</button>
                -->
                </bxs-col>
            </bxs-row>
        </bxs-layout>
      <div class="nav-bg"></div>
    </header>
</template>

<script>
export default {
    name: 'bxs-navbar',

    computed: {
      isHome() {
          if (this.$route.path === '/' || this.$route.path === '/home') {
            return true
          } else {
            return false
          }
        }
    },
    methods: {
      toggleAppScroll(bool) {
        const globalScroll = this.$root.scroll
        bool ? globalScroll.scroll.destroy() : globalScroll.scroll.init()
      },
      menuAction(event) {
        event.preventDefault()
        this.$refs.menuBtn.classList.toggle('active')
        this.$store.commit('toggle_is_mega_menu')
        this.toggleAppScroll(this.$store.getters.get_is_mega_menu)
      }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';

$border: 1px solid rgba(255, 255, 255, .2);
$borderDark: 1px solid rgba(0, 0, 0, .3);

.plcd-navbar {
    position: fixed;
    width: 100%;
    min-height: var(--navbar-min-height);
    background-color: var(--navbar-background-color);
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    align-items: center;
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
    z-index: var(--layer-navbar);
    transition: transform 0.5s ease-out;
    border-bottom: $borderDark;

    &.hideNav{
      transform: translate(0, -120%);
    }

    .logo{
      @include breakpoint(tablet_portrait) {
        max-width: 160px !important;
      }
    }

    > .bxs-layout {
        height: 100%;
        // padding-top: var(--navbar-padding-y);
        // padding-bottom: var(--navbar-padding-y);
        // padding-top: 20px;
        // padding-bottom: 20px;
        background-color: rgba(255, 255, 255, 0);

        @include breakpoint(tablet_portrait) {
          padding: 16px 0;
        }

        > .bxs-row {
            height: 100%;

            .bxs-col {
                height: 100%;
            }
        }
    }
    .desktop-navbar{
      @include breakpoint(app_mobile){
        display: none;
      }
    }
    .mobile-navbar{
      display: none;

      @include breakpoint(app_mobile){
        display: flex;
      }
    }
}

.desktop-navbar{
  z-index: 2;
  width: 100%;
  height: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: 14vw 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  position: relative;

  .link-wrapper{
    @include SideRegular;
    color: #333;
    text-decoration: none !important;
    text-transform: uppercase;
    letter-spacing: .02em;
    height: 100%;
    text-align: center;
    border-right: $borderDark;
    position: static;
    text-decoration: none;

    a{
      padding: 19.235%;
      text-decoration: none;
      position: static;
      font-size: 15px;

      .trigger{
        display: flex;
        flex-flow: column;
        flex: none;
        justify-content: flex-start;
        overflow: hidden;
        height: 16px;
        margin: 10px 0 10px;

        span{
          transition: all .3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        }
      }
    }

    > a{
      width: 100%;
      height: 100%;
    }

    a:hover{
      .trigger{
        span{
          transform: translate3d(0, -100%, 0);
        }
      }
    }

    &:nth-child(2){
      border-left: $borderDark;
    }
  }

  .contact-link{
    border: none;
    background-color: var(--color-primary);
    transition: all .5s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    &:hover{
      background: rgba(255, 255, 255, .0)
    }
  }

  .subnav{
    position: absolute;
    display: none;
    left: 50%;
    top: 116px;
    border: $borderDark;
    border-right: none;
    border-left: none;
    width: 100vw;
    transform: translate(-50%, 0);

    a{
      padding: 0;
      margin: 25px 40px;
      text-decoration: none;
    }
  }

  .link-wrapper:hover{
    > .subnav{
      display: block;
    }
  }
}

.lang-selector{
  position: absolute !important;
  right: -63px;
  top: 30px;
  border: none !important;
  height: 50px;

  .trigger{
    width: 50px;
  }

  .lang-label{
    @include SideRegular;
    text-transform: uppercase;
    color: white;
    text-decoration: none !important;
    text-transform: uppercase;
    letter-spacing: .02em;
    text-align: center;
    text-decoration: none;
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }

/*
  > a{
      width: 30px;
      height: 20px;
      display: block;
      background-size: cover !important;
      margin-bottom: 40px;

    &.it{
      display: none;
      background:transparent url('@/assets/img/it.svg') center center no-repeat;
    }
    &.en{
      display: none;
      background:transparent url('@/assets/img/en.svg') center center no-repeat;
    }
  }
*/
}

.plcd-navbar.homeNav{
  border-bottom: $border;

  .link-wrapper{
    border-right: $border;

    &:nth-child(2){
      border-left: $border;
    }

    a{
      color: white;
    }
  }

  .subnav{
    border: $border;
  }
}

.nav-bg{
  z-index: -1;
  width: 100vw;
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  background-color: rgba(255, 255, 255, .05);
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 0%;
  bottom: 0;
  left: 0%;
  right: 0%;
}

/* NAVBAR MOVEMENTS */

/****
*** PLATES ***
****/

svg {
  height: 80px;
  position: absolute;
  width: 80px;

  @include breakpoint(tablet_portrait) {
    height: 60px;
    width: 60px;
  }
}
.plates {
  display: flex;
  flex-wrap: wrap;
  max-height: 160px;
  width: 640px;
}
@media (max-width: 640px) {
  .plates {
    width: 320px;
  }
}
.plate {
  height: 80px;
  width: 80px;
  margin-left: auto;
  padding-right: 80px;

  @include breakpoint(tablet_portrait) {
    height: 60px;
    width: 60px;
    padding-right: 60px;
  }

  &:hover{
    .plate-label{
      transform: translate(-100%, 0);
      opacity: 1;
    }
  }

  &.active{
    .plate-label{
      opacity: 0 !important;
    }
  }
}
.plate-label{
  @include SideBold;
  position: absolute;
  margin: 25px 5px 0;
  font-size: 24px;
  opacity: 0;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.burger {
  filter: url(#gooeyness);
}
.x {
  transform: scale(0);
  transition: transform 400ms;
}
.line {
  fill: none;
  stroke: var(--color-primary);
  stroke-width: 6px;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform-origin: 50%;
  transition: stroke-dasharray 500ms 200ms, stroke-dashoffset 500ms 200ms, transform 500ms 200ms;
}
.x .line {
  stroke-width: 5.5px;
}
/* First plate */
.plate1 .line2 {
  stroke-dasharray: 40 200;
  stroke-dashoffset: 0px;
}
.plate1 .line3 {
  stroke-dasharray: 40 179;
  stroke-dashoffset: 0px;
}
.active .line {
  transition: stroke-dasharray 500ms, stroke-dashoffset 500ms, transform 500ms;
}
.active.plate1 .line1 {
  transform: scale(0, 1);
  transition: transform 500ms 100ms;
}
.active.plate1 .line2 {
  stroke-dasharray: 5px 200;
  stroke-dashoffset: -164px;
}
.active.plate1 .line3 {
  stroke-dasharray: 5px 179;
  stroke-dashoffset: -142px;
}
.active .x {
  transform: scale(1);
  transition: transform 400ms 350ms;
}
/* El segundo plato */
.plate2 .line1 {
  stroke-dasharray: 21 185.62753295898438;
  transition-delay: 0;
}
.plate2 .line2 {
  stroke-dasharray: 21 178.6514129638672;
  transition-delay: 30ms;
}
.plate2 .line3 {
  stroke-dasharray: 21 197.92425537109375;
  transition-delay: 60ms;
}
.plate2 .line4 {
  stroke-dasharray: 21 190.6597137451172;
  transition-delay: 90ms;
}
.plate2 .line5 {
  stroke-dasharray: 21 208.52874755859375;
  transition-delay: 120ms;
}
.plate2 .line6 {
  stroke-dasharray: 21 186.59703063964844;
  transition-delay: 150ms;
}
.active.plate2 .line1 {
  stroke-dasharray: 5 185.62753295898438;
  stroke-dashoffset: -141px;
}
.active.plate2 .line2 {
  stroke-dasharray: 5 178.6514129638672;
  stroke-dashoffset: -137px;
}
.active.plate2 .line3 {
  stroke-dasharray: 5 197.92425537109375;
  stroke-dashoffset: -176px;
}
.active.plate2 .line4 {
  stroke-dasharray: 5 190.6597137451172;
  stroke-dashoffset: -159px;
}
.active.plate2 .line5 {
  stroke-dasharray: 5 208.52874755859375;
  stroke-dashoffset: -139px;
}
.active.plate2 .line6 {
  stroke-dasharray: 5 186.59703063964844;
  stroke-dashoffset: -176px;
}
.active.plate2 .x {
  transition: transform 400ms 250ms;
}

/* O terceiro prato */
.plate3 .line {
  transition: stroke-dasharray 300ms 200ms, stroke-dashoffset 300ms 200ms, transform 300ms 200ms;
}
.plate3 .line1 {
  stroke-dasharray: 21 109;
}
.plate3 .line2 {
  stroke-dasharray: 21 112;
}
.plate3 .line3 {
  stroke-dasharray: 21 102;
}
.plate3 .line4 {
  stroke-dasharray: 21 103;
}
.plate3 .line5 {
  stroke-dasharray: 21 110;
}
.plate3 .line6 {
  stroke-dasharray: 21 115;
}
.plate3 .x {
  transition: transform 400ms 50ms;
}

.active.plate3 .line {
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms, transform 400ms;
}
.active.plate3 .line1 {
  stroke-dasharray: 5 109;
  stroke-dashoffset: -95px;
}
.active.plate3 .line2 {
  stroke-dasharray: 5 112;
  stroke-dashoffset: -96px;
}
.active.plate3 .line3 {
  stroke-dasharray: 5 102;
  stroke-dashoffset: -79px;
}
.active.plate3 .line4 {
  stroke-dasharray: 5 103;
  stroke-dashoffset: -78px;
}
.active.plate3 .line5 {
  stroke-dasharray: 5 110;
  stroke-dashoffset: -63px;
}
.active.plate3 .line6 {
  stroke-dasharray: 5 115;
  stroke-dashoffset: -64px;
}
.active.plate3 .x {
  transition: transform 400ms 100ms;
}
/* Die vierte teller */
.plate4 .x {
  transition: transform 400ms;
}
.plate4 .line {
  transform-origin: 50%;
  transition: transform 400ms 100ms;
}
.active.plate4 .line {
  transition: transform 400ms;
}
.active.plate4 .line1 {
  transform: translateX(18px) translateY(-3px) rotate(-45deg) scale(.7);
}
.active.plate4 .line2 {
  transform: translateX(-18px) translateY(-3px) rotate(45deg) scale(.7);
}
.active.plate4 .line3 {
  transform: translateY(0px) rotate(45deg) scale(.7);
}
.active.plate4 .line4 {
  transform: translateY(0px) rotate(-45deg) scale(.7);
}
.active.plate4 .line5 {
  transform: translateX(18px) translateY(3px) rotate(45deg) scale(.7);
}
.active.plate4 .line6 {
  transform: translateX(-18px) translateY(3px) rotate(-45deg) scale(.7);
}
.active.plate4 .x {
  transition: transform 400ms 100ms;
  transform: scale(1);
}
/* Den femte rätten */
.plate5 .line {
  transition: stroke-dasharray 400ms 100ms, stroke-dashoffset 400ms 100ms, transform 400ms 100ms;
}
.plate5 .line1 {
  stroke-dasharray: 40 40;
}
.plate5 .line2 {
  stroke-dasharray: 21 39;
}
.plate5 .line3 {
  stroke-dasharray: 21 39;
}
.plate5 .line4 {
  stroke-dasharray: 40 40;
}
.plate5 .x {
  transition: transform 400ms 50ms;
}

.active.plate5 .line {
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms, transform 400ms;
}
.active.plate5 .line1 {
  stroke-dasharray: 1 40;
  stroke-dashoffset: -33px;
}
.active.plate5 .line2 {
  stroke-dasharray: 5 39;
  stroke-dashoffset: -37px;
}
.active.plate5 .line3 {
  stroke-dasharray: 5 39;
  stroke-dashoffset: -37px;
}
.active.plate5 .line4 {
  stroke-dasharray: 1 40;
  stroke-dashoffset: -33px;
}
.active.plate5 .x {
  transition: transform 400ms 50ms;
}
/* Шестая пластина */
.plate6 .line {
  transition: stroke-dasharray 400ms 100ms, stroke-dashoffset 400ms 100ms, transform 400ms 100ms;
}
.plate6 .line1 {
  stroke-dasharray: 21 127;
}
.plate6 .line2 {
  stroke-dasharray: 21 106;
}
.plate6 .line3 {
  stroke-dasharray: 21 106;
}
.plate6 .line4 {
  stroke-dasharray: 21 126;
}
.plate6 .x {
  transition: transform 400ms 50ms;
}

.active.plate6 .line {
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms, transform 400ms;
}
.active.plate6 .line1 {
  stroke-dasharray: 2 127;
  stroke-dashoffset: -94px;
}
.active.plate6 .line2 {
  stroke-dasharray: 2 106;
  stroke-dashoffset: -91px;
}
.active.plate6 .line3 {
  stroke-dasharray: 2 106;
  stroke-dashoffset: -91px;
}
.active.plate6 .line4 {
  stroke-dasharray: 2 127;
  stroke-dashoffset: -94px;
}
.active.plate6 .x {
  transition: transform 400ms 50ms;
}
/* 제 7 판 */
.plate7 .line {
  transition: stroke-dasharray 400ms 100ms, stroke-dashoffset 400ms 100ms, transform 400ms 100ms;
}
.plate7 .line1 {
  stroke-dasharray: 21 102;
}
.plate7 .line2 {
  stroke-dasharray: 21 92;
}
.plate7 .line3 {
  stroke-dasharray: 21 97;
}
.plate7 .line4 {
  stroke-dasharray: 21 97;
}
.plate7 .line5 {
  stroke-dasharray: 21 92;
}
.plate7 .line6 {
  stroke-dasharray: 21 102;
}
.plate7 .x {
  transition: transform 400ms 50ms;
}

.active.plate7 .line {
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms, transform 400ms;
}
.active.plate7 .line1 {
  stroke-dasharray: 2 102;
  stroke-dashoffset: -102px;
}
.active.plate7 .line2 {
  stroke-dasharray: 2 92;
  stroke-dashoffset: -92px;
}
.active.plate7 .line3 {
  stroke-dasharray: 2 97;
  stroke-dashoffset: -97px;
  transition-delay: 20ms;
}
.active.plate7 .line4 {
  stroke-dasharray: 2 97;
  stroke-dashoffset: -97px;
  transition-delay: 20ms;
}
.active.plate7 .line5 {
  stroke-dasharray: 2 92;
  stroke-dashoffset: -90px;
  transition-delay: 40ms;
}
.active.plate7 .line6 {
  stroke-dasharray: 2 102;
  stroke-dashoffset: -100px;
  transition-delay: 40ms;
}
.active.plate7 .x {
  transition: transform 400ms 50ms;
}
/* Όγδοο πιάτο */
.plate8 .line {
  transition: stroke-dasharray 400ms 100ms, stroke-dashoffset 400ms 100ms, transform 400ms 100ms;
}
.plate8 .line1 {
  stroke-dasharray: 21 201;
}
.plate8 .line2 {
  stroke-dasharray: 21 201;
  transition-delay: 20ms;
}
.plate8 .line3 {
  stroke-dasharray: 21 201;
  transition-delay: 40ms;
}
.plate8 .line4 {
  stroke-dasharray: 21 201;
  transition-delay: 60ms;
}
.plate8 .line5 {
  stroke-dasharray: 21 201;
  transition-delay: 80ms;
}
.plate8 .line6 {
  stroke-dasharray: 21 201;
  transition-delay: 100ms;
}
.plate8 .x {
  transition: transform 400ms 50ms;
}

.active.plate8 .line {
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms, transform 400ms;
}
.active.plate8 .line1 {
  stroke-dasharray: 5 201;
  stroke-dashoffset: -158px;
}
.active.plate8 .line2 {
  stroke-dasharray: 5 201;
  stroke-dashoffset: -158px;
  transition-delay: 20ms;
}
.active.plate8 .line3 {
  stroke-dasharray: 5 201;
  stroke-dashoffset: -158px;
  transition-delay: 40ms;
}
.active.plate8 .line4 {
  stroke-dasharray: 5 201;
  stroke-dashoffset: -158px;
  transition-delay: 60ms;
}
.active.plate8 .line5 {
  stroke-dasharray: 5 201;
  stroke-dashoffset: -158px;
  transition-delay: 80ms;
}
.active.plate8 .line6 {
  stroke-dasharray: 5 201;
  stroke-dashoffset: -158px;
  transition-delay: 100ms;
}
.active.plate8 .x {
  transition: transform 400ms 50ms;
}

//
/*
.menu-toggle {
	flex:0 0 auto;
	width: 40px;
	height: 40px;
	border: 1px solid rgb(0, 0, 0);
	cursor: pointer;
	position:relative;
}

.hamburger,
.cross{
	position:absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.hamburger span{
	display:block;
	width: 18px;
	height: 1px;
	margin-bottom: 4px;
	overflow: hidden;
	position: relative;
}

.hamburger span:last-child{
	margin:0;
}

.hamburger span:before,.hamburger span:after{
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgb(0, 0, 0);
	transform: translateX(-200%);
	transition:transform ease 300ms;
}

.hamburger span:after{
	transform: translateX(0);
}

.hamburger span:nth-child(2):before,
.hamburger span:nth-child(2):after{
	transition-delay: 75ms;
}

.hamburger span:last-child:before,
.hamburger span:last-child:after{
	transition-delay: 150ms;
}

.menu-toggle:hover .hamburger span:before{
	transform: translateX(0);
}

.menu-toggle:hover .hamburger span:after{
	transform: translateX(200%);
}

.menu-toggle.menu-toggle-active .hamburger span:before{
	transform: translateX(100%);
}

.menu-toggle.menu-toggle-active .hamburger span:after{
	transform: translateX(200%);
}

.cross span {
	display: block;
	width: 18px;
	height: 2px;
	background-color: rgb(0, 0, 0);
	transform: translateY(50%) rotate(45deg) scaleX(0);
	transition: transform ease 200ms;
}

.cross span:last-child{
	transform: translateY(-50%) rotate(-45deg) scaleX(0);
}

.menu-toggle.menu-toggle-active .cross span{
	transition-delay: 450ms;
	transform: translateY(50%) rotate(45deg) scaleX(1);
}

.menu-toggle.menu-toggle-active .cross span:last-child{
	transform: translateY(-50%) rotate(-45deg) scaleX(1);
}
*/
</style>
