<template>
  <div
  class="big-menu"
  v-if="is_MegaMenu"
  >
      <bxs-layout class="big-menu--content">
          <div ref="menuWrapper">
              <bxs-row>
                <bxs-col cols="6" ds="6">
                  <ul ref="menuContent">
                      <li ref="firstLevel">
                        <router-link to="#">About Us</router-link>
                        <ul>
                          <li>
                            <router-link to="#">Team</router-link>
                          </li>
                          <li>
                            <router-link to="#">Su Misura</router-link>
                          </li>
                        </ul>
                      </li>
                      <li ref="firstLevel">
                        <router-link to="#">Tecnologia</router-link>
                        <ul>
                          <li>
                            <router-link to="#">Differenza metodi di sterilizzazione</router-link>
                          </li>
                        </ul>
                      </li>
                      <li ref="firstLevel">
                        <router-link to="#">Principi Attivi</router-link>
                      </li>
                      <li ref="firstLevel">
                        <router-link to="#">Applicazioni</router-link>
                        <ul>
                          <li>
                            <router-link to="#">Differenza metodi di sterilizzazione</router-link>
                          </li>
                          <li>
                            <router-link to="#">Polimeri</router-link>
                          </li>
                          <li>
                            <router-link to="#">Effetti dei raggi gamma</router-link>
                          </li>
                        </ul>
                      </li>
                      <li ref="firstLevel">
                        <router-link to="#">Certificazioni</router-link>
                      </li>
                      <li ref="firstLevel">
                        <router-link to="#">News</router-link>
                        <ul>
                          <li>
                            <router-link to="#">Press</router-link>
                          </li>
                          <li>
                            <router-link to="#">Media</router-link>
                          </li>
                          <li>
                            <router-link to="#">Storytelling</router-link>
                          </li>
                        </ul>
                      </li>
                      <li ref="firstLevel">
                        <router-link to="#">Contatti</router-link>
                      </li>
                      <!--
                      <li
                      v-for="(item, i) in items"
                      :key="i">
                          <router-link
                          to="#"
                          exact
                          class="h3"
                          data-big-menu-item>
                              <span>{{ item }}</span>
                              <c-arrow />
                          </router-link>
                          -->
                          <!--
                            <router-link
                            :to="'/' + item.page.slug"
                            exact
                            class="h3"
                            data-big-menu-item>
                                <span>{{ item.title }}</span>
                                <c-arrow />
                            </router-link>
                          -->
                      <!--
                      </li>
                          -->
                  </ul>
                </bxs-col>
                <bxs-col cols="6" ds="6">
                  <ul class="side-links">
                    <li><a href="#" target="_blank">Facebook</a></li>
                    <li><a href="#" target="_blank">LinkedIn</a></li>
                    <li><a href="#" target="_blank">YouTube</a></li>
                  </ul>
                </bxs-col>
              </bxs-row>
              <div class="language">
                <lang-selector>LANG SELECTOR</lang-selector>
              </div>
          </div>

          <div class="big-menu--content--footer">
              <bxs-row
              no-gutters
              align="center">

                  <bxs-col
                  cols="6" ds="4"
                  class="col-hide-ds">
                      <div
                      class="text-left">
                      © Copyright {{ year }} Gammatom Srl. All Right Reserved
                      </div>
                  </bxs-col>

                  <bxs-col cols="6" ds="4">
                      <div
                      class="text-center">
                      </div>
                  </bxs-col>

                  <bxs-col cols="6" ds="4">
                      <div
                      class="text-right">
                        <a href="mailto:info@gammato.it">info@gammatom.it</a>
                      </div>
                  </bxs-col>
              </bxs-row>
          </div>
      </bxs-layout>
  </div>
</template>

<script>

export default {
  name: 'plcd-mega-menu',

  computed: {
      is_MegaMenu() {
        return this.$store.getters.get_is_mega_menu
      }
  },

  data() {
    return {
      items: {
        0: 'About Us',
        1: 'Tecnologia',
        2: 'Principi Attivi',
        3: 'Applicazioni',
        4: 'Certificazioni'
      }
    }
  }

  /*
  computed: {
      ...mapState({
          items: state => state.menu
      })
  }
  */
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mq.scss';
@import '@/assets/styles/mixins.scss';
$menuBlack: #222;

.big-menu{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  padding-top: 100px;
  background-color: var(--color-primary);
  background-color: #ffb990;
  z-index: var(--layer-drawer);

  .side-links{
    li{
      a{
        font-size: 28px;
      }
    }
  }

  &--content{
      position: relative;
      height: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      padding-bottom: var(--grid-gutter);

      div{
          overflow: hidden;

          > ul{
              > li{
                  overflow: hidden;
                  height: var(--big-menu-list-item-height);

                  // @include mq (ml) {
                  //     height: calc(var(--navbar-min-height) * 1.5);
                  // }

                  // @include mq (dl) {
                  //     height: calc(var(--navbar-min-height) * 1.75);
                  // }

                  &:last-child {
                      border-bottom: none;
                  }

                  ul {
                    display: none;
                  }

                  > a {
                      position: relative;
                      font-size: 52px;
                      text-decoration: none;
                      display: inline-block;

                      @media (hover: hover) {
                          &:hover {
                              color: white;
                          }
                      }

                      &.router-link-active {
                          color: white;
                      }
                  }
              }
          }
      }

      &--footer{
          @include MainRegular;
          height: var(--big-menu-footer-min-height);
          overflow: hidden;
          color: $menuBlack;
          font-size: 18px;
          padding-top: 20px;
          border-top: 1px solid $menuBlack;

          a{
            @include SideRegular;
            text-decoration: none;
            white-space: nowrap;
            color: $menuBlack;
          }

          > .bxs-row{
              height: 100%;
          }
      }
  }
}
</style>
