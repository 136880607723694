<template>
  <div class="page">
      <!-- HEADING 1 IMAGE BIG -->

      <plcd-page-heading
        :title="page.title"
        :subtitle="page.plcd_page_subtitle"
        :svgbg="true"
      >
      </plcd-page-heading>

      <section class="page-content" id="contentSection">
          <bxs-row>
            <bxs-col
            cols="12"
            ds="12"
            >
              <div class="page-content__text">
                <div
                v-if="!is_preloaded"
                class="text-center">
                    <bxs-layout>
                        <bxs-loader />
                    </bxs-layout>
                </div>

                <div v-else>
                    <div>
                        <bxs-layout>
                            <bxs-row>
                                <bxs-col
                                v-for="item in data_page.docs"
                                :key="item.id"
                                cols="12"
                                ds="4"
                                class="mb-6">
                                  <router-link
                                    :to="item.to"
                                    class="news"
                                    >
                                    <bxs-figure class="news--figure" :src="item.thumb_url" ratio="16/9">
                                    </bxs-figure>
                                    <div class="news--content">
                                        <h3 class="news--content--title">{{ item.title }}</h3>
                                        <div class="news--content--paragraph" v-html="item.content">
                                        </div>
                                        <bxs-btn
                                        append-icon="arrow-right"
                                        text>Approfondisci</bxs-btn>
                                    </div>
                                  </router-link>
                                </bxs-col>
                            </bxs-row>
                        </bxs-layout>
                    </div>
                </div>
              </div>
            </bxs-col>
          </bxs-row>
      </section>

      <plcd-driving-banner
        :title="page.plcd_driving_banner_page_title"
        :to="page.plcd_driving_banner_link"
        :src="page.plcd_driving_banner_image"
      >
      </plcd-driving-banner>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'press-releases',
    data () {
        return {
            is_preloaded: false,
            is_busy: false,
            scroll: null,
            data_page: {
                category: null,
                docs: [],
                pagination: {
                    page: 1
                }
            }
        }
    },
    computed: {
      ...mapState({
        language: state => state.language,
        pages(state) {
          const languagePages = {
              it: state.pages,
              en: state.pagesEn
          }
          return languagePages[this.language] || state.pages
          },
          page() {
            const objArray = Object.values(this.pages)
            return objArray.filter(pages => pages.template === 'template-press-releases.php')[0]
          },
          activeLang() {
            return this.$store.state.language
          }
      })
    },
    mounted () {
        this.$nextTick(this.start)/*
        const interval = setInterval(() => {
          // console.log(this.$root.scroll, 'SCROLLLLL')

          if (this.data_page.pagination.has_next_page && !this.is_busy) {
              this.getDocs(this.data_page.pagination.page += 1)
          }
          this.$root.resize()
        }, 2000)

        clearInterval(interval)
        */
    },
    methods: {
        async start () {
            await this.getDocs()
            this.is_preloaded = true
            // this.$root.scroll.on('scroll', this.handleScroll)
        },
        stop () {
        },
        handleScroll () {
            if (
                (window.innerHeight + window.scrollY) >= (document.body.scrollHeight - (window.innerHeight / 2))
            ) {
                if (this.data_page.pagination.has_next_page && !this.is_busy) {
                    this.getDocs(this.data_page.pagination.page += 1)
                }
            }
        },
        async getDocs (page = 1, slug) {
            this.is_busy = true

            try {
                const {
                    category,
                    docs,
                    pagination
                } = await this.$store.dispatch('getNews', {
                    limit: -1,
                    page: page,
                    ...this.$route.query
                })

                this.data_page.category = category

                if (page === 1) this.data_page.docs = docs
                else this.data_page.docs.push(...docs)

                this.data_page.pagination = pagination
            } catch (err) {
                alert(err)
            } finally {
                this.is_busy = false
                this.is_preloaded = true
            }
        }
    },
    beforeRouteUpdate (to, from) {
      this.getDocs(1, to.params.slug)
    },
    beforeUnmount () {
       this.stop()
    }
}
</script>

<style scoped lang="scss">
.news--content--paragraph{
  height: 140px;
  overflow: hidden;
}
</style>
