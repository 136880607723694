<template>
  <div
  :class="['collapse-item-comp', {
      actived: on,
      'collapse-item-comp--no-border': hideBorder
  }]"
  @click="toggle">
      <bxs-list-item
      link
      :divider="!on"
      :prepend-icon="!on ? 'chevron-down' : 'chevron-top'">
          <span v-if="label">{{ label }}</span>
          <slot name="label" />
      </bxs-list-item>

      <div
      ref="content"
      class="collapse-item-comp--cnt">
          <slot />
      </div>
  </div>
</template>

<script>
export default {
  name: 'collapse-item',
  props: {
      label: {
          type: String,
          required: false,
          default: ''
      },
      'hide-border': {
          type: Boolean,
          required: false,
          default: false
      }
  },
  data () {
      return {
          on: false
      }
  },
  methods: {
      toggle () {
          this.on = !this.onClick

          this.$nextTick(() => {
              const cnt = this.$refs.content

              if (cnt.style.maxHeight) {
                  cnt.style.maxHeight = null
              } else {
                  cnt.style.maxHeight = cnt.scrollHeight + 'px'
              }
              setTimeout(() => {
                this.$root.resize()
              }, 400)
          })
      }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mq.scss';

.collapse-item-comp {
  position: relative;

  &.actived {
      > .collapse-item-comp--cnt {
          background: #eee;
          // padding-top: var(--size-ui);
          // padding-bottom: var(--size-ui);
      }
  }

  &--no-border {
      border: none;
  }

  &--cnt {
      max-height: 0;
      transition: max-height 0.2s ease-out;
      padding-left: var(--list-item-padding-x);
      padding-right: var(--list-item-padding-x);
      text-transform: none;

      overflow-x: hidden;
      overflow-y: scroll;
      scroll-behavior: auto;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar {
          display: none;
      }
  }
}
</style>
