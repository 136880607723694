<template>
  <div class="page">
      <!-- HEADING 1 IMAGE BIG -->

      <plcd-page-heading
        title="Il Nostro Team"
        subtitle="Il Team di Gammatom"
        :svgbg="true"
      >
      </plcd-page-heading>

      <section class="page-content">
        <bxs-layout class="bxs-layout-fluid bxs-layout-vertical-padded">
          <bxs-row>
            <bxs-col
            cols="12"
            ds="12"
            class="bxs-col-no-padding"
            >
            <div class="staff-wrapper">
              <div v-if="staff">
                <div class="staff-member">
                  <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="5"
                    class="bxs-col-no-padding"
                    >
                      <img :src="staff.full_img" :alt="staff.title">
                    </bxs-col>
                    <bxs-col
                    cols="12"
                    ds="7"
                    class="bxs-col-no-padding"
                    >
                      <div class="staff-member-data">
                        <div class="title">{{ staff.title }}</div>
                        <div class="department">{{ staff.department }}</div>
                        <div class="phone">
                          <a :href="`tel:${staff.phone}`" target="_blank" v-if="staff.phone">
                            <bxs-icon name="telephone" width="20px" height="20px"></bxs-icon>
                            <span style="margin-left: 15px">{{ staff.phone }}</span>
                          </a>
                        </div>
                        <div class="email">
                          <a :href="`mailto:${staff.email}`" target="_blank" v-if="staff.email">
                            <bxs-icon name="envelope" width="20px" height="20px"></bxs-icon>
                            <span style="margin-left: 15px">{{ staff.email }}</span>
                          </a>
                        </div>
                        <div class="socials">
                          <a href="https://www.facebook.com/Gammatom.it/" target="_blank">
                            <bxs-icon name="fb" width="24px" height="24px"></bxs-icon>
                          </a>
                          <a href="https://www.linkedin.com/company/gammatom-srl/" target="_blank">
                            <bxs-icon name="in" width="24px" height="24px"></bxs-icon>
                          </a>
                        </div>
                        <!-- {{ staff.sociables }} -->
                      </div>
                    </bxs-col>
                  </bxs-row>
                  <bxs-row>
                    <div class="content" v-html="staff.content"></div>
                  </bxs-row>
                </div>
              </div>
            </div>
            </bxs-col>
          </bxs-row>
        </bxs-layout>
      </section>

    <plcd-driving-banner
      title="Il Nostro Team"
      to="/our-team-grid"
      src="https://gammatom.sviluppo-web.net/wp-content/uploads/2019/12/cover-company-profile.jpg"
    >
    </plcd-driving-banner>

  </div>
</template>

<script>
import Http from '@/assets/libs/Http'
export default {
    name: 'staff',
    data () {
        return {
            staff: null
        }
    },
    created() {
      this.getStaff()
    },
    methods: {
      async getStaff() {
          const wp = new Http(process.env.VUE_APP_WP_API)
          const route = this.$route.path.replace('/staff/', '')
          this.staff = await wp.getPost(route, 'staff')
          return this.staff
      }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mixins.scss';
.staff-wrapper{
  max-width: 1000px;
  margin: 0 auto;
}
.staff-member{
  .content{
    margin: 32px 0;
    padding: 0 10px;
    color: #131a22;
    opacity: 0.8;
    line-height: 26px;
  }
}
.staff-member-data{
  padding: 60px 0 0 60px;

  .title{
    font-size: 30px;
    @include MainRegular;
  }
  .department{
    @include MainBold;
    color: var(--color-primary);
    margin-bottom: 20px;
    margin-top: 5px;
    letter-spacing: .02em;
  }
  .email, .phone{
    margin: 12px 0;
  }
  .socials{
    margin-top: 30px;

    .bxs-icon{
      color: var(--color-primary);
      margin-right: 12px;
    }
  }
  a{
    color: black;
  }
}
</style>
