<template>
  <span class="text-extrovert">
      <span class="content__text" data-splitting><slot name="word_one"></slot></span>
      <span class="content__text" data-splitting><slot name="word_two"></slot></span>
      <span class="content__text" data-splitting><slot name="word_three"></slot></span>
  </span>

</template>

<script>
import Splitting from '@/assets/libs/splitting.min.js'
import { gsap } from 'gsap'
export default {
    name: 'plcd-text-extrovert',
    props: {
        animation: {
            type: Number,
            required: false,
            default: 0
        },
        infinite: {
          type: Boolean,
          required: false,
          default: false
        }

    },

    data: function() {
      return {
        upcomingTextPos: 0
      }
    },

    mounted() {
      this.horizontal_flipping(true)
    },

    methods: {
      horizontal_flipping() {
        // console.log(this.infinite)

        // Calling the Splitting function to split the text into individual words/characters,
        const splittingOutput = Splitting()
        // .content__text elements
        const texts = [...document.querySelectorAll('.content__text')]
        // Cache all .char elements at the beginning. Each text contains multiple words, each word contains multiple chars.
        const chars = texts.map(text => {
            // Get the words for each text
            const words = text.querySelectorAll('.word')
            // For each word, get the chars
            return [...words].map(word => word.querySelectorAll('.char'))
        })

        // Let's define the position of the current text
        let currentTextPos = 0
        // Check if there's an animation in progress
        let isAnimating = false
        // Add class current to the "current" one
        texts[currentTextPos].classList.add('content__text--current')

        // Set perspective
        splittingOutput.map(output => output.words).flat().forEach(word => {
            gsap.set(word, { perspective: 1000 })
        })

        // switch between texts
        const switchTexts = () => {
          if (isAnimating) return false
          isAnimating = true

          if (this.upcomingTextPos === 2) {
            currentTextPos = 2
            this.upcomingTextPos = 0
          } else {
            this.upcomingTextPos = currentTextPos + 1
          }

          // All current text words
          const currentWords = splittingOutput[currentTextPos].words
          // All upcoming text words
          const upcomingtWords = splittingOutput[this.upcomingTextPos].words
          const tl = gsap.timeline({
              onComplete: () => {
                  // Update currentTextPos
                  currentTextPos = this.upcomingTextPos
                  isAnimating = false
              }
          })
          currentWords.forEach((_, wordIndex) => {
              const wordTimeline = gsap.timeline()
              .fromTo(chars[currentTextPos][wordIndex], {
                  willChange: 'transform',
                  rotationY: 0
              }, {
                  duration: 0.6,
                  ease: 'expo',
                  opacity: 0,
                  rotationY: 90,
                  stagger: {
                      each: 0.04,
                      from: 'end'
                  }
              })
              tl.add(wordTimeline, Math.random() * 0.5)
          })

          tl.add(() => {
              texts[currentTextPos].classList.remove('content__text--current')
          })
          tl.add(() => {
              texts[this.upcomingTextPos].classList.add('content__text--current')
          }, '>-=.8')
          .addLabel('previous', '>')

          upcomingtWords.forEach((_, wordIndex) => {
              const wordTimeline = gsap.timeline()
              .fromTo(chars[this.upcomingTextPos][wordIndex], {
                  willChange: 'transform',
                  opacity: 0,
                  rotationY: 90
              }, {
                  duration: 0.6,
                  ease: 'expo',
                  opacity: 1,
                  rotationY: 0,
                  stagger: {
                      each: 0.04,
                      from: 'end'
                  }
              })
              tl.add(wordTimeline, `previous+=${Math.random() * 0.5}`)
          })
        }
        if (this.infinite) {
          setInterval(switchTexts, 3000)
        } else {
          document.querySelector('.text-extrovert-trigger').addEventListener('click', switchTexts)
        }
    }
  }
}
</script>
